@media (min-width: 1000px) {
    .menu-big {
        min-width: 70%;
    }
}

.menu-full {
    min-width: 100%;
}


@media (min-width: 1000px) {
    .border-right {
        border-right: 1px solid #a1a1a1;
    }

    .scrolling {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 80px);
        overflow-y: auto;
    }
}

.result-format {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    font-style: normal;
    cursor: default;
    white-space: pre-line;
}

.border-bottom {
    border-bottom: 1px solid #a1a1a1;
}

.rs-loader-backdrop {
    background: rgb(255 255 255 / 76%)!important;
}

.rs-loader-content {
    font-size: 20px!important;
    font-weight: 700!important;
}

.rs-loader-spin::after {
    border-width: 5px!important;
    border-color: #575757 transparent transparent !important;
}