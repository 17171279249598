
.trend-box {
    background-color: white!important;

    .title {
        font-size: 22px;
        color: black;
        font-weight: 600;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;


    }

    .description {
        padding-top: 20px;
        color: black;
        font-size: 16px;
        font-weight: 500

    }

}

.tmp-row {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .rs-panel {
        height: 100%;
    }

    .rs-stack-item:last-child {
        width: 100%;
    }
}

.tmp-row:before {
    display: none!important;
}

.db-coll {
    padding-bottom: 1rem;
    height: 100%;
}

.kind-img {
    height: 150px;
    margin-bottom: 10px;
}

@media (max-width: 1660px) {
    .db-coll {
        padding-bottom: 1rem;
    }
}


@media (max-width: 1000px) {
    .db-coll {
        width: 100% !important;
        padding-bottom: 1rem;
    }
}


@media (max-width: 920px) {
    .db-coll {
        //width: 100% !important;
    }

    .tmp-row {
        grid-template-columns: 1fr;
    }

    .kind-img {
        height: 100px!important;
    }
    .trend-box {
        min-height: auto!important;
    }
}

.tags-tmp {
}

.tags-btn {
    padding-bottom: 20px;
}

.tags-btn > button {
    font-weight: 600!important;
    //background-color: transparent;
    //border: 2px solid var(--rs-btn-ghost-border)
}

.tags-btn > button.active:hover  {
    color: var(--rs-btn-primary-text);
    border: 1px solid var(--rs-btn-ghost-border)
}

.tags-btn > button.active:focus  {
    outline: none;
    border: 1px solid var(--rs-btn-ghost-border)
}

.tags-btn > button:focus  {
    outline: none;
    border: 1px solid var(--rs-btn-ghost-border)
}

.pulse {
    animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
}

