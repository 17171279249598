.top-level-nav {

  background: #272c36;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
}

@media (min-width: 768px) {
  .top-level-nav {
    position: fixed;
    padding: 10px;
    width: 64px;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
}

